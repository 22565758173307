import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  InfoBox,
  OverlayViewF,
  OverlayView,
} from "@react-google-maps/api";
import ExplorerPropertyDetailsWindow from "../components/common/Explorer/ExplorerPropertyDetailsWindow";
import "./Map.css";
import { useNavigate, useSearchParams } from "react-router-dom";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const defaultCenter = {
  lat: 20.667618,
  lng: -103.373183,
};

function Map(props) {
  const {
    properties,
    propertyHoverOnList,
    isSearchByAreaVisible,
    setIsSearchByAreaVisible
  } = props;

  const { isLoaded } = useJsApiLoader({
    id: process.env.REACT_APP_GOOGLE_MAP_ID,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  });
  const [hoverProperty, setHoverProperty] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  

  const [northEast, setNorthEast] = useState(defaultCenter);
  const [southWest, setSouhtWest] = useState(defaultCenter);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const mapRef = useRef();

  const onMapLoad = (map) => {
    mapRef.current = map;
  };

  // Implement debounce later for performance
  const handleBoundsChanged = () => {
    const newNorthEast = mapRef.current.getBounds().getNorthEast().toJSON();
    const newSouthWest = mapRef.current.getBounds().getSouthWest().toJSON();

    const diffLat = Math.abs(northEast.lat - newNorthEast.lat);
    const diffLng = Math.abs(northEast.lng - newNorthEast.lng);

    const diagDiff = Math.sqrt(diffLat * diffLat + diffLng * diffLng);

    const latSize = Math.abs(northEast.lat - southWest.lat);
    const lngSize = Math.abs(northEast.lng - southWest.lng);

    if (diagDiff >= latSize * 0.4 || diagDiff >= lngSize * 0.4) {
      // Check if initial state is different to avoid display when page is loaded
      if (northEast != defaultCenter && southWest != defaultCenter) {
        setIsSearchByAreaVisible(true);
      }
      setNorthEast(newNorthEast);
      setSouhtWest(newSouthWest);
    }
  };

  const handleSearchByArea = async () => {
    var params = Object.fromEntries(searchParams.entries());
    const newNorthEast = mapRef.current.getBounds().getNorthEast().toJSON();
    const newSouthWest = mapRef.current.getBounds().getSouthWest().toJSON();
    var areaToSearch = [
      [newNorthEast.lng, newNorthEast.lat],
      [newNorthEast.lng, newSouthWest.lat],
      [newSouthWest.lng, newSouthWest.lat],
      [newSouthWest.lng, newNorthEast.lat],
      [newNorthEast.lng, newNorthEast.lat],
    ];
    var newParameters = {
      ...params,
      area: JSON.stringify(areaToSearch),
    };
    const query = new URLSearchParams(newParameters).toString();
    setIsSearchByAreaVisible(false);
    navigate(`/map?${query}`);
  };

  useEffect(() => {
    setHoverProperty(propertyHoverOnList);
  }, [propertyHoverOnList]);

  return isLoaded ? (
    <div className="explorer-map-container">
      {isSearchByAreaVisible ? (
        <div
          className="explorer-map-area-search-container"
          onClick={handleSearchByArea}
        >
          Buscar en esta área
        </div>
      ) : null}
      <GoogleMap
        zoom={12}
        options={{
          disableDefaultUI: true,
          styles: [
            {
              featureType: "poi",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
        center={defaultCenter}
        mapContainerStyle={containerStyle}
        onLoad={onMapLoad}
        onBoundsChanged={handleBoundsChanged}
      >
        {properties
          ? properties?.map((property) => (
              <OverlayViewF
                key={property._id}
                position={{
                  lng: property?.location?.coordinates[0],
                  lat: property?.location?.coordinates[1],
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                zIndex={`${hoverProperty === property._id ? 2 : 1}`}
              >
                <div
                  className={`map-custom-marker ${
                    hoverProperty === property._id ? "hovered" : ""
                  }`}
                  onMouseEnter={() => setHoverProperty(property._id)}
                  onMouseLeave={() => setHoverProperty(null)}
                  onClick={() => {
                    setIsDetailsOpen(true);
                    setSelectedProperty(property);
                  }}
                >
                  {property.price} MXN
                </div>
              </OverlayViewF>
            ))
          : null}

        {selectedProperty ? (
          <InfoBox
            position={{
              lng: selectedProperty?.location?.coordinates[0],
              lat: selectedProperty?.location?.coordinates[1],
            }}
            options={{
              closeBoxURL: "",
              enableEventPropagation: true,
              pixelOffset: new window.google.maps.Size(0, 0),
            }}
          >
            <ExplorerPropertyDetailsWindow
              property={selectedProperty}
              onClose={() => setIsDetailsOpen(false)}
              isOpen={isDetailsOpen}
            />
          </InfoBox>
        ) : null}
      </GoogleMap>
    </div>
  ) : (
    <p>Loading....</p>
  );
}

export default Map;
