import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";

function Login() {
  const { login, user } = useContext(AuthContext);
  const [email, setEmail] = useState("admin");
  const [password, setPassword] = useState("123");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/profile");
    }
  }, [user]);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
    } catch {
      console.log("Credenciales invalidas");
    }
  };

  return (
    <div>
      <h4>Login</h4>
      <form onSubmit={handleLogin}>
        <input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
        <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
        <button type="submit">Iniciar sesion</button>
      </form>
    </div>
  );
}

export default Login;
