import React from "react";
import "./ExplorerPropertyCard.css";

export default function ExplorerPropertyCard(props) {
  const { property } = props;
  const testProperty = {
    id: 1,
    title: "Modern Downtown Apartment",
    location: property.city, //"123 Main St, Cityville",
    price: 2500,
    bedrooms: 2,
    bathrooms: 2,
    area: 1000,
    image:
      "https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg",
  };

  return (
    <div key={property.id} className="explorer-property-card-container">
      <div className="explorer-property-card-image">
      <img
          src={testProperty.image}
          alt={testProperty.title}
        />
      </div>
      <div className="explorer-property-card-content">
        <div className="explorer-property-card-location">
          <svg
            viewBox="0 0 24 24"
            width="18"
            height="18"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
          >
            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
            <circle cx="12" cy="10" r="3"></circle>
          </svg>
          <span>{testProperty.location}</span>
        </div>
        <div className="explorer-property-card-features">
          <div>
            <span>{testProperty.bedrooms} cuartos</span>
          </div>
          <div>
            <span>{testProperty.bathrooms} baños</span>
          </div>
          <div>
            <span>{testProperty.area} m2</span>
          </div>
        </div>
        <div className="explorer-property-card-contact-price">
          <div className="explorer-property-card-contact">Posted by Carlos</div>
          <div className="explorer-property-card-price">${property.price}</div>
        </div>
      </div>
    </div>
  );
}
