import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOAD_TEST_DATA } from "../graphql/property";
function Profile() {
  const { logout, user, loadingAuth } = useContext(AuthContext);
  const [loadTestDataMutation, { loading, error, data }] =
    useMutation(LOAD_TEST_DATA);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  const handleLogout = async () => {
    try {
      await logout();
    } catch {}
  };
  const handleLoadTestData = async () => {
    await loadTestDataMutation();
  };

  return (
    <div>
      <h4>Perfil</h4>
      <button onClick={handleLogout}>Logout</button>
      {!loadingAuth && user.role == "Administrator" ? (
        <div>
          <button onClick={() => navigate("/admin")}>
            Administrar propiedades
          </button>
          {loading ? (
            <div> Loading... </div>
          ) : (
            <button onClick={handleLoadTestData}> Load test data </button>
          )}
        </div>
      ) : null}
    </div>
  );
}
export default Profile;
