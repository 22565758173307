import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { GET_PROPERTIES } from "../graphql/property";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ExplorerSearchBar from "../components/common/Explorer/ExplorerSearchBar";
import ExplorerPropertyCard from "../components/common/Explorer/ExplorerPropertyCard";
import Map from "./Map";
import "./Explorer.css";

function Explorer() {
  // We are gonna use user location as default center
  const defaultCenter = {
    lat: 20.667618,
    lng: -103.373183,
  };

  const [properties, setProperties] = useState([]);
  const [propertyHover, setPropertyHover] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchByAreaVisible, setIsSearchByAreaVisible] = useState(false);

  const { refetch } = useQuery(GET_PROPERTIES, {
    skip: true,
    fetchPolicy: "network-only",
  });

  const location = useLocation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        var params = Object.fromEntries(searchParams.entries());
        if (params.area) {
          params = {
            ...params,
            area: JSON.parse(params.area),
          };
        }
        params = {
          ...params,
          city: params.city ? params.city : null,
          maxPrice: params.maxPrice ? parseInt(params.maxPrice, 10) : null,
          minPrice: params.minPrice ? parseInt(params.minPrice, 10) : null,
        }
        const { loading, data } = await refetch(params);
        if (!loading && data) {
          setProperties(data?.getProperties);
        }
        setIsSearchByAreaVisible(false)
      } catch {}
    };

    fetchData();
  }, [location.search]);

  return (
    <div>
      <ExplorerSearchBar
      />
      <div className="explorer-container-properties-map">
        <div className="explorer-container-list-property-card">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            properties.map((property) => (
              <li
                key={property._id}
                className="explorer-container-property-card"
                onMouseEnter={() => setPropertyHover(property._id)}
                onMouseLeave={() => setPropertyHover("")}
              >
                <Link to={`/property-details/${property._id}`}>
                  <ExplorerPropertyCard property={property} />
                </Link>
              </li>
            ))
          )}
        </div>
        {isLoading ? null : (
          <Map
            properties={properties}
            propertyHoverOnList={propertyHover}
            isSearchByAreaVisible={isSearchByAreaVisible}
            setIsSearchByAreaVisible={setIsSearchByAreaVisible}
          />
        )}
      </div>
    </div>
  );
}

export default Explorer;
