import React from "react";

import "./AdminPropertyStatusDetails.css";
import { Link } from "react-router-dom";
function AdminPropertyStatusDetails(props) {
  const { property } = props;
  return property ? (
    <div className="admin-property-status-details-container">
      <div className="admin-property-status-details-image-container">
        <img
          src={
            "https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"
          }
        />
      </div>
      <div className="admin-property-status-details-property-details-link-container">
        <Link to={`/property-details/${property._id}`}>
          See property details
        </Link>
      </div>
      {property.status}
    </div>
  ) : (
    <p>No tienes propiedades para mostrar</p>
  );
}

export default AdminPropertyStatusDetails;
