import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./HomeSearchBarHeader.css";
function HomeSearchBarHeader(props) {
  const [location, setLocation] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const navigate = useNavigate();

  const searchProperties = async (e) => {
    e.preventDefault();
    var searchBarParameters = {};
    if (location) {
      searchBarParameters.city = location;
    }
    if (minPrice) {
      searchBarParameters.minPrice = minPrice;
    }
    if (maxPrice) {
      searchBarParameters.maxPrice = maxPrice;
    }

    const query = new URLSearchParams(searchBarParameters).toString();
    navigate(`/map?${query}`);
  };

  return (
    <div className="home-search-bar-header-container">
      <div className="home-search-bar-header-find-bar-container">
        <div className="home-search-bar-header-find-text-container">
          <p>Buscar</p>
        </div>
        <div className="home-search-bar-header-bar-container">
          <form
            className="home-search-bar-header-search-form"
            onSubmit={searchProperties}
          >
            <input
              type="text"
              placeholder="Enter location"
              className="home-search-bar-header-search-input home-search-bar-header-location-input"
              onChange={(e) => setLocation(e.target.value)}
            />
            <select className="home-search-bar-header-search-input home-search-bar-header-type-select">
              <option value="">Property Type</option>
              <option value="apartment">Apartment</option>
              <option value="house">House</option>
              <option value="condo">Condo</option>
              <option value="townhouse">Townhouse</option>
            </select>
            <input
              type="number"
              placeholder="Max Price"
              className="home-search-bar-header-search-input home-search-bar-header-price-input"
              onChange={(e) =>
                setMaxPrice(e.target.value ? Number(e.target.value) : null)
              }
            />
            <select className="home-search-bar-header-search-input home-search-bar-header-looking-for-select">
              <option value="">Looking to...</option>
              <option value="rent">Rent</option>
              <option value="buy">Buy</option>
            </select>
            <button
              type="submit"
              className="home-search-bar-header-search-button"
              aria-label="Search"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="home-search-bar-header-search-icon"
              >
                <circle cx="11" cy="11" r="8" />
                <line x1="21" y1="21" x2="16.65" y2="16.65" />
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default HomeSearchBarHeader;
