import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PROPERTY_BY_ID } from "../../graphql/property";
import PropertyImageExpanded from "../../components/common/Property/PropertyImageExpanded";

import "./PropertyDetails.css";
function PropertyDetails() {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_PROPERTY_BY_ID, {
    variables: { id },
  });
  const [property, setProperty] = useState(null);
  const [images, setImages] = useState([null, null, null, null, null]);
  const [displayImages, setDisplayImages] = useState(false);

  useEffect(() => {
    if (!loading && data) {
      setProperty(data.getPropertyById);
      var tempImages = images;
      const imagesSize = data.getPropertyById.images.length;
      for (let i = 0; i < (5 < imagesSize ? 5 : imagesSize); i++) {
        tempImages[i] = data.getPropertyById.images[i];
      }
      setImages(tempImages);
    }
  }, [loading]);
  return loading ? (
    <p>Loading...</p>
  ) : property ? (
    <div className="property-details-container">
      <div className="property-details-title-container">
        <p>Entire House in Capitol Hill-Blocks from Downtown!</p>
      </div>
      <div className="property-details-banner-images-container">
        <div className="property-details-banner-images-left-container">
          <img
            src={
              "https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"
            }
            onClick={() => setDisplayImages(true)}
          />
        </div>
        <div className="property-details-banner-images-right-container">
          <img
            src={
              "https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"
            }
            onClick={() => setDisplayImages(true)}
          />
          <img
            src={
              "https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"
            }
            style={{ borderRadius: "0px 20px 0px 0px" }}
            onClick={() => setDisplayImages(true)}
          />
          <img
            src={
              "https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"
            }
            onClick={() => setDisplayImages(true)}
          />
          <img
            src={
              "https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"
            }
            style={{ borderRadius: "0px 0px 20px 0px" }}
            onClick={() => setDisplayImages(true)}
          />
        </div>
      </div>
      <div className="property-details-information-container">
        <div className="property-details-information-details-section-container">
          <div className="property-details-initial-description-container">
            <p>Entire home in Seattle, Washington</p>
          </div>
          <div className="property-details-location-container">
            <p>Av de la Paz 1483 Col. Americana, Americana, Guadalajara</p>
          </div>
          <div className="property-details-owner-container">
            <p>Publicado por Carlos</p>
          </div>
          <div className="property-details-description-container">
            <h4>Descripcion</h4>
            <br/>
            <p>
              Vive en el "Barrio más cool del mundo" ATRIA TOWER es un
              desarrollo vertical en el corazón de la colonia Americana, una de
              las mejores colonias del mundo para vivir, gracias a su riqueza en
              gastronomía, cultura y vida nocturna. ¡A unas cuadras de
              Chapultepec! (Colonia rankeada por la revista Time Out). Contamos
              con departamentos desde 36 hasta 101 m2, los cuales te ofrecen
              excelentes alternativas para tu estilo de vida y amenidades como:
              *Sky Bar *Game zone *Coworking *Pet zone *Cine *Yoga Deck *Jardín
              Zen *Fire Pit y mucho más.
            </p>
          </div>
        </div>
        <div className="property-details-information-contact-section-container">
          <h4>Ponte en contacto con {'Carlos'} por esta propiedad</h4>
          <div className="property-details-information-contact-owner-card-container">
            <p>Carlos</p>
            <p>Telefono: 123 456 7890</p>
          </div>
        </div>
      </div>
      <PropertyImageExpanded
        displayImages={displayImages}
        setDisplayImages={setDisplayImages}
        url={
          "https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"
        }
      />
    </div>
  ) : (
    <p>No existing property</p>
  );
}

export default PropertyDetails;
