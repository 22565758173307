import React, { useEffect, useRef, useState } from "react";
import "./ExplorerPropertyDetailsWindow.css";

function ExplorerPropertyDetailsWindow(props) {
  const { property, onClose, isOpen } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const popupRef = useRef(null);

  const images = [
    "https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg",
  ];

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [isOpen, onClose]);

  return isOpen ? (
    <div className="explorer-details-window-overlay">
      <div ref={popupRef} className="explorer-details-window-container">
        <button className="explorer-details-window-close-button">x</button>
        <div className="explorer-details-window-carousel-container">
          <img src={images[currentImageIndex]} />
          <button className="explorer-details-window-carousel-button prev">
            {"<"}
          </button>
          <button className="explorer-details-window-carousel-button next">
            {">"}
          </button>
        </div>
        <div className="explorer-details-window-property-details-container">
          <h2>Luxurious Beachfront Villa</h2>
          <p className="explorer-details-window-property-details-price">
            ${property.price}
          </p>
          <div className="explorer-details-window-property-details-features-container">
            <span> --- 4 Beds</span>
            <span> --- 3 Baths</span>
            <span> --- 2,500 sqft</span>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default ExplorerPropertyDetailsWindow;
