import { gql } from "@apollo/client";

export const CREATE_PROPERTY = gql`
  mutation createProperty($address: String!, $description: String!) {
    createProperty(address: $address, description: $description)
  }
`;

export const LOAD_TEST_DATA = gql`
  mutation loadTestData {
    loadTestData
  }
`

export const GET_PROPERTIES_BY_OWNER = gql`
  query getPropertiesByOwner($owner_id: ID!){
    getPropertiesByOwner(owner_id: $owner_id) {
      _id
      city
      address
      status
      price
      location {
        type
        coordinates
      }
    }
  }
`

export const GET_ALL_PROPERTIES = gql`
  query getAllProperties {
    getAllProperties {
      _id
      city
      price
      location {
        type
        coordinates
      }
    }
  }
`
export const GET_PROPERTY_BY_ID = gql`
  query getPropertyById($id: ID!){
    getPropertyById(id: $id) {
      _id
      images
      price
      location {
        type
        coordinates
      }
    }
  }
`

export const GET_PROPERTIES = gql`
  query getProperties($city: String, $neighborhood: String, $maxPrice: Int, $minPrice: Int, $area: [[Float]]){
    getProperties(city: $city, neighborhood: $neighborhood, maxPrice: $maxPrice, minPrice: $minPrice, area: $area) {
      _id
      city
      price
      location {
        type
        coordinates
      }
    }
  }
`