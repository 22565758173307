import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { VERIFY_EMAIL } from "../graphql/user";

function VerifyEmail() {
  const { token } = useParams();
  const [verifyMutation, { loading, error, data }] = useMutation(VERIFY_EMAIL);

  useEffect((e) => {
    if (token) {
      verifyMutation({
        variables: { token },
      });
    }
  }, [token]);
  return loading ? <p>Loading...</p> : (data?.verifyEmail ? <div>Correo verificado! Ahora puedes iniciar sesion desde login.</div> : <div>Hubo un error, vuelve a intentarlo mas tarde.</div>);
}

export default VerifyEmail;
