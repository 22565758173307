import React, { useEffect, useState } from "react";

import "./PropertyImageExpanded.css"
function PropertyImageExpanded(props) {
  const { displayImages, setDisplayImages, url } = props;

  return displayImages ? (
    <div className="property-image-expanded-container">
      <div className="property-image-expanded-close-button" onClick={() => setDisplayImages(false)}>X Close</div>
      <img src={url} />
    </div>
  ) : null;
}

export default PropertyImageExpanded;
