import React from "react";
import AdminCreatePropertyForm from "../../components/protected/AdminCreatePropertyForm";
function AdminCreateProperty() {
  return (
    <div>
      <AdminCreatePropertyForm/>
    </div>
  )
}
export default AdminCreateProperty;
