import React from "react";

import "./HomePropertyCard.css";

function HomePropertyCard(props) {
  const { property } = props;

  return (
    <div className="home-property-card-container">
      <div className="home-property-card-image-container">
        <img src={"https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"}/>
      </div>
      <div className="home-property-card-about-container">
        <div className="home-propety-card-about-location">
            <p>1420 West Street, Seattle, WA, USA</p>
        </div>
        <div className="home-property-card-about-status">
            <p className={`home-property-card-about-status-${property.status}`}>{property.status}</p>
        </div>
      </div>
    </div>
  );
}

export default HomePropertyCard;
