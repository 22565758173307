import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN, LOGOUT, CHECK_AUTH, REGISTER } from "../graphql/user";
import { useMutation, useQuery } from "@apollo/client";

const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [loginMutation] = useMutation(LOGIN);
  const [logoutMutation] = useMutation(LOGOUT);
  const [registerMutation] = useMutation(REGISTER);
  const { loading, error, data } = useQuery(CHECK_AUTH);
  useEffect(() => {
    if (data) {
      setUser(data.checkAuth);
    }
    setLoadingAuth(loading);
  }, [data, loading]);
  const login = async (email, password) => {
    try {
      const { data } = await loginMutation({ variables: { email, password } });
      setUser(data.login);
    } catch (error) {
      console.log(error);
    }
  };
  const logout = async () => {
    try {
      const { data } = await logoutMutation();
      setUser(!data.logout);
    } catch (error) {
      console.log(error);
    }
  };
  const register = async (name, email, password, role) => {
    try {
      const { data } = await registerMutation({
        variables: { name, email, password, role },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <AuthContext.Provider
      value={{ user, login, logout, register, loadingAuth }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
