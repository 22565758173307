import React, { useContext } from "react";
import { Navigate, Route } from "react-router-dom";
import { AuthContext } from "../../context/authContext";

const AdminRoute = ({ path, element: Element, userRole }) => {
  const { loadingAuth, user } = useContext(AuthContext);
  if (loadingAuth) {
    return <p>Loading...</p>;
  } else {
    return user ? (
      user.role == "Administrator" ? (
        <Element />
      ) : (
        <Navigate to="/login" replace />
      )
    ) : (
      <Navigate to="/login" replace />
    );
  }
};
export default AdminRoute;
