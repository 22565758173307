import "./App.css";
import client from "./apolloClient";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./context/authContext";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import UserRoute from "./components/protected/UserRoute";
import AdminRoute from "./components/protected/AdminRoute";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Navbar from "./components/common/Navbar";
import Register from "./pages/Register";
import AdminCreateProperty from "./pages/Admin/AdminCreateProperty";
import AdminProperties from "./pages/Admin/AdminProperties";
import Explorer from "./pages/Explorer";
import PropertyDetails from "./pages/Properties/PropertyDetails";
import VerifyEmail from "./pages/VerifyEmail";

function App() {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthContextProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/map" element={<Explorer />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<UserRoute element={Profile}/>} />
            <Route path="/property-details/:id" element={<PropertyDetails />} />
            <Route path="/verify/email/:token" element={<VerifyEmail />} />
            <Route
              path="/admin"
              element={<AdminRoute element={AdminDashboard} />}
            />
            <Route
              path="/admin/create-property"
              element={<AdminRoute element={AdminCreateProperty} />}
            />
            <Route
              path="/admin/properties"
              element={<AdminRoute element={AdminProperties} />}
            />
          </Routes>
        </AuthContextProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;
