import React, { useContext, useState, useEffect } from "react";
import { GET_PROPERTIES_BY_OWNER } from "../../graphql/property";
import { AuthContext } from "../../context/authContext";
import { useQuery } from "@apollo/client";
import AdminPropertyCard from "../../components/protected/AdminPropertyCard";
import AdminPropertyStatusDetails from "../../components/protected/AdminPropertyStatusDetails";

import "./AdminProperties.css";
function AdminProperties() {
  const { user, loadingAuth } = useContext(AuthContext);
  const [properties, setProperties] = useState([]);
  const [propertySelected, setPropertySelected] = useState(null);

  const { loading, error, data } = useQuery(GET_PROPERTIES_BY_OWNER, {
    fetchPolicy: "network-only",
    skip: loadingAuth,
    variables: { owner_id: user._id },
  });

  useEffect(() => {
    if (!loading && data) {
      setProperties(data.getPropertiesByOwner);
      setPropertySelected(data.getPropertiesByOwner[0])
    }
  }, [loading]);

  return (
    <div className="admin-properties-container">
      <div className="admin-properties-title-list-container">
        <div className="admin-properties-title-container">
          <p>Properties</p>
        </div>
        <div className="admin-properties-list-container">
          {loadingAuth || loading ? (
            <p>Loading...</p>
          ) : (
            properties.map((property) => (
              <li key={property._id} className={`admin-properties-list-item-container ${propertySelected._id == property._id ? 'selected' : ""}`} onClick={() => setPropertySelected(property)}>
                <AdminPropertyCard property={property} />
              </li>
            ))
          )}
        </div>
      </div>
      <AdminPropertyStatusDetails property={propertySelected}/>
    </div>
  );
}
export default AdminProperties;
