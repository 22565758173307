import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import InitialOptionCard from "./InitialOptionCard";

import "./InitialOptionsContainer.css";
import imageInitialOptionBuy from "../../../images/initial-option-buy.jpg";
import imageInitialOptionRent from "../../../images/initial-option-rent.jpg";
import imageInitialOptionLoan from "../../../images/initial-option-loan.jpg";
import imageInitialOptionAgent from "../../../images/initial-option-agent.jpg";
import imageInitialOptionZones from "../../../images/initial-option-zones.jpg";

function InitialOptionsContainer() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const cards = [
    <InitialOptionCard text="COMPRAR" url={imageInitialOptionBuy} key={0} />,
    <InitialOptionCard text="RENTAR" url={imageInitialOptionRent} key={1} />,
    <InitialOptionCard
      text="MEJORES ZONAS "
      url={imageInitialOptionZones}
      key={2}
    />,
    <InitialOptionCard
      text="CONTACTA A UN AGENTE"
      url={imageInitialOptionAgent}
      key={3}
    />,
    <InitialOptionCard
      text="OBTENER UN PRESTAMO"
      url={imageInitialOptionLoan}
      key={4}
    />,
  ];

  const handleCarouselAction = (num) => {
    setCurrentIndex((currentIndex + num) % 5);
  };

  useEffect(() => {
    const handleScreenResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleScreenResize);
    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  });

  return (
    <div className="home-initial-options-container">
      <div className="home-initial-options-container-title-container">
        <p>La mejor forma para encontrar tu nuevo hogar</p>
      </div>

      {screenWidth > 510 ? (
        <div className="home-intial-options-cards-container">
          {cards.map((card) => card)}
        </div>
      ) : (
        <div className="home-initial-options-cards-carousel-container">
          <div
            className="home-initial-options-cards-carousel-container-button"
            onClick={() => handleCarouselAction(4)}
          >
            {"<"}
          </div>
          {cards[currentIndex]}
          <div
            className="home-initial-options-cards-carousel-container-button"
            onClick={() => handleCarouselAction(1)}
          >
            {">"}
          </div>
        </div>
      )}
    </div>
  );
}

export default InitialOptionsContainer;
