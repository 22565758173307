import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./ExplorerSearchBar.css";
function ExplorerSearchBar(props) {

  const [location, setLocation] = useState("");
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState("");

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const searchProperties = async (e) => {
    var params = Object.fromEntries(searchParams.entries());
    e.preventDefault();
    var searchBarParameters = {};
    if (location) {
      searchBarParameters.city = location;
    }
    if (minPrice) {
      searchBarParameters.minPrice = minPrice;
    }
    if (maxPrice) {
      searchBarParameters.maxPrice = maxPrice;
    }
    var newParameters = {
      ...params,
      ...searchBarParameters,
    };

    if(!location) {
      delete newParameters.city
    }
    if(!maxPrice) {
      delete newParameters.maxPrice
    }
    if(!minPrice) {
      delete newParameters.minPrice
    }
    const query = new URLSearchParams(newParameters).toString();
    navigate(`/map?${query}`);
  };

  useEffect(() => {
    var params = Object.fromEntries(searchParams.entries());
    if (params?.city) {
      setLocation(params.city);
    }
    if (params?.maxPrice) {
      setMaxPrice(parseInt(params.maxPrice, 10))
    }
  }, []);

  return (
    <div className="explorer-search-bar-container">
      <form
        className="explorer-search-bar-search-form-container"
        onSubmit={searchProperties}
      >
        <input
          type="text"
          placeholder="Enter location"
          className="explorer-search-bar-search-input location-input"
          value={location}
          onChange={(e) => setLocation(e.target.value ? e.target.value : "")}
        />
        <select className="explorer-search-bar-search-input type-select">
          <option value="">Property Type</option>
          <option value="apartment">Apartment</option>
          <option value="house">House</option>
          <option value="condo">Condo</option>
          <option value="townhouse">Townhouse</option>
        </select>
        <input
          type="number"
          placeholder="Max Price"
          className="explorer-search-bar-search-input price-input"
          value={maxPrice}
          onChange={(e) =>
            setMaxPrice(e.target.value ? Number(e.target.value) : "")
          }
        />
        <select className="explorer-search-bar-search-input looking-for-select">
          <option value="">Looking to...</option>
          <option value="rent">Rent</option>
          <option value="buy">Buy</option>
        </select>
        <button
          type="submit"
          className="explorer-search-bar-search-button"
          aria-label="Search"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="explorer-search-bar-search-icon"
          >
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
          </svg>
        </button>
      </form>
    </div>
  );
}

export default ExplorerSearchBar;
