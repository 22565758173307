import React from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

import "./InitialOptionCard.css";

function InitialOptionCard(props) {
  const { text, url } = props;
  const cardStyle = {
    backgroundImage: `url(${url})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div className="home-initial-option-card" style={cardStyle}>
      <div className="home-initial-option-card-text-container">
        <p>{text}</p>
      </div>
    </div>
  );
}

export default InitialOptionCard;
