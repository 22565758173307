import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      _id
      name
      role
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;

export const REGISTER = gql`
  mutation register($name: String!, $email: String!, $password: String!, $role: String!) {
    register(name: $name, email: $email, password: $password, role: $role)
  }
`

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const GET_USERS = gql`
  query getUsers {
    getUsers {
      id
      name
    }
  }
`;

export const CHECK_AUTH = gql`
  query checkAuth {
    checkAuth {
      _id
      name
      role
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query getAllRoles {
    getAllRoles {
      _id
      name
    }
  }
`