import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_ROLES } from "../graphql/user";

function Register() {
  const { register, user } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const { loading, error, data } = useQuery(GET_ALL_ROLES);
  const [verificationSent, setVerificationSent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/profile");
    }
    if (data?.getAllRoles) {
      setRoles(data.getAllRoles);
    }
  }, [user, loading]);

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const result = await register(name, email, password, role);
      setVerificationSent(result);
    } catch {
      console.log("Credenciales invalidas");
    }
  };
  return loading ? (
    <p>loading....</p>
  ) : (
    <div>
      {!verificationSent ? (
        <form onSubmit={handleRegister}>
          <input
            type="text"
            placeholder="name"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="itemSelect">Select an Item:</label>
          <select
            id="itemSelect"
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="">Choose here</option>
            {roles.map((item, index) => (
              <option key={index} value={item._id}>
                {item.name}
              </option>
            ))}
          </select>
          <button type="submit">Register</button>
        </form>
      ) : verificationSent?.register ? (
        <p>
          Enviamos un correo de confirmation a tu email, una vez verificado
          podras iniciar sesion desde la pagina de login
        </p>
      ) : (
        <p>Hubo un error, vuelve a intentarlo de nuevo</p>
      )}
    </div>
  );
}

export default Register;
