import React from "react";
import "./AdminPropertyCard.css";

function AdminPropertyCard(props) {
  const { property } = props;
  return (
    <div className="admin-property-card-container">
      <div className="admin-property-card-image-container">
        <img src={"https://www.houseplans.net/news/wp-content/uploads/2023/07/57260-768.jpeg"}/>
      </div>
      <div className="admin-property-card-about-container">
        <div className="admin-propety-card-about-location">
            <p>1420 West Street, Seattle, WA, USA</p>
        </div>
        <div className="admin-property-card-about-status">
            <p className={`admin-property-card-about-status-${property.status}`}>{property.status}</p>
        </div>
      </div>
    </div>
  );
}

export default AdminPropertyCard;
