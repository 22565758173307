import React from "react";
import HomeSearchBarHeader from "./HomeSearchBarHeader";

import "./HomeSearchHeader.css";
function HomeSearchHeader() {
  return (
    <div className="home-search-header-container">
      <HomeSearchBarHeader />
      <div className="home-search-header-title-container">
        <p>La forma mas facil de encontrar el lugar perfecto</p>
      </div>
    </div>
  );
}

export default HomeSearchHeader;
