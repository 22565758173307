import React, { useContext } from "react";
import { Navigate, Route } from "react-router-dom";
import { AuthContext } from "../../context/authContext";

const UserRoute = ({ path, element: Element, userRole }) => {
  const { loadingAuth, user } = useContext(AuthContext);
  if (loadingAuth) {
    return <p>Loading...</p>;
  } else {
    return user ? <Element /> : <Navigate to="/login" replace />;
  }
};
export default UserRoute;
