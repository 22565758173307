import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";

function AdminDashboard() {
  const { logout, user, loadingAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div>
      <p>Dashboard for Admin</p>
      {!loadingAuth && user.role === "Administrator" ? (
        <div>
          <button onClick={() => navigate("/admin/create-property")}>
            Agregar una propiedad
          </button>
          <button onClick={() => navigate("/admin/properties")}>
            Mis propiedades
          </button>
        </div>
      ) : null}
    </div>
  );
}
export default AdminDashboard;
