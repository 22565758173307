import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/authContext"
import { useNavigate } from "react-router-dom";
import { CREATE_PROPERTY } from "../../graphql/property"
import { useMutation, useQuery } from "@apollo/client";

function Register() {
  const { user } = useContext(AuthContext);
  const [createPropertyMutation] = useMutation(CREATE_PROPERTY);
  const [address, setAddress] = useState(null);
  const [description, setDescription] = useState(null);
  const navigate = useNavigate();

  const createPropertyAction = async (e) => {
    e.preventDefault();
    try {
        const data = await createPropertyMutation({ variables: { address, description } });
        // Should redirect to property details
        console.log(data)
      } catch (error) {
        console.log(error);
      }
  };
  return (
    <div>
      <form onSubmit={createPropertyAction}>
        <input type="text" placeholder="address" required onChange={(e) => setAddress(e.target.value)}/>
        <input type="text" placeholder="description" required onChange={(e) => setDescription(e.target.value)}/>
        <button type="submit">Create property</button>
      </form>
    </div>
  );
}

export default Register;
